@font-face {
  font-family: 'Morro-Regular';
  src: url('./assets/fonts/Morro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Morro-Outline';
  src: url('./assets/fonts/Morro-Outline.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Morro-Shadow';
  src: url('./assets/fonts/Morro-Shadow.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Thin';
  src: url('./assets/fonts/Outfit-Thin.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Light';
  src: url('./assets/fonts/Outfit-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Regular';
  src: url('./assets/fonts/Outfit-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Medium';
  src: url('./assets/fonts/Outfit-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit-Bold';
  src: url('./assets/fonts/Outfit-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Outfit-Light", sans-serif !important;
}

button {
  font-family: "Outfit-Light", sans-serif !important;
}

.container {
  overflow-y: auto;
  height: 100vh;
  padding-left: 12.5%;
  padding-right: 12.5%;
  margin-bottom: 100px;
  text-align: left;
  font-size: 16px;
  line-height: 1.5em;
  color: #333;
}

#main_header {
  height: 125px;
  width: 100vw;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px) !important;
  background-color: transparent;
  position: fixed;
  top: 0px;
  position: absolute;
  left: 0px;
  z-index: 2;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.3);
}

#main_header::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  /* Adjust the height to the desired border thickness */
  background: linear-gradient(to right, #ff7f5f00, #000000, #ff7f5f00);
  /* Gradient colors */
}

.heading {
  font-family: 'Morro-Regular', sans-serif !important;
  font-weight: normal;
  padding-bottom: 20px;
  font-size: 2em;
  color: #111;
  text-transform: uppercase;
}

#privacy p {
  width: 100%;
}

#feed {
  top: 65%;
  position: absolute;
  overflow: hidden;
  height: 20%;
  z-index: 10000000;
}




.menu-decider {
  width: 50px;
  height: 50px;
  transform: rotate(0deg);
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 7px 2px;
  border-radius: 35px;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2;
  user-select: none;
}

@media (max-width: 768px) {
  #feed {
    width: 100%
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #feed {
    width: 60%
  }
}

@media (min-width: 1024px) {
  #feed {
    width: 40%
  }
}

.title-item {
  color: white;
  padding: 10px;
}
.feed-item {
  opacity:0;
  color: white;
  padding: 10px;
}

.feed-title {
  cursor: pointer;
  font-family: "Outfit-Thin";
  font-weight: 100;
  padding-bottom: 10px;
  font-size: 12px;
}

.feed-date {
  font-family: "Outfit-Thin";
  font-size: 9px;
  font-weight: bold;
}

.list {
  width: 100vw;
  height: 100vh;
  background-color: rgb(65, 65, 180);
  clip: rect(0px 0vw 100vh 0px);
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
}

.menu-link {
  cursor: pointer;
  font-family: "Morro-Regular";
  font-size: 3em;

}

.menu-link::first-letter {
  color: rgba(0, 0, 0, 0.6)
}

.logo {
  cursor: pointer;
  transition: filter 1s ease-out, transform 0.25s ease-out;
  left: 25px;
  top: 25px;
  width: 250px;
  position: absolute;
  z-index: 100000;
  transform:scale(1);
  transform-origin: left center;
}

@media (max-width: 460px) {
   .logo {
    transform:scale(0.65);
  }
}

#contact input, textarea{
  font-family: "Outfit-Regular" !important;
}